<template>
  <div class="editor-container">
    <v-card flat style="height: 100%; overflow: scroll;">
      <div>
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
        <p class="font-regular mt-2 px-5" >Set the timeframe for this report.</p>
      </div>
      <v-container class="hb-table" >
          <v-row class="hb-table-row"  v-for="section in filter_structure" :key="section.key">
            <v-col class="hb-table-label pl-6 pt-5" cols="4" style="font-weight: 500;" :style="{color: !isEmpty(form[section.key]) ? '#00848e': ''}">
              {{section.label | capitalize | removeUnderscore}}
            </v-col>
            <v-col class="hb-table-value" v-if="section.input === 'multi-select' && $store.getters['filterStore/'+section.key]">
              <v-autocomplete
                :items="$store.getters['filterStore/' + section.key]"
                v-model="form[section.key]"
                item-text="name"
                item-value="id"
                hide-details
                multiple
                dense
                solo
                flat
                clearable
                background-color="white"
                placeholder="Select"
              ></v-autocomplete>
            </v-col>
            <v-col class="hb-table-value " v-if="section.input === 'multi-select' && section.options && section.options.length" style="padding: 3px 0;">

              <v-autocomplete
                :items="section.options"
                v-model="form[section.key]"
                item-text="name"
                item-value="id"
                hide-details
                multiple
                dense
                solo
                flat
                clearable
                background-color="white"
                placeholder="Select"
              >
              </v-autocomplete>
            </v-col>


            <v-col class="hb-table-value " v-if="section.input === 'boolean'">
              <v-row >
               <v-checkbox v-model="form[section.key]" class="mx-3 my-0" hide-details value="0" label="No"></v-checkbox>
               <v-checkbox v-model="form[section.key]" class="mx-3 my-0" hide-details value="1" label="Yes"></v-checkbox>
              </v-row>
            </v-col>

            <v-row v-if="section.input === 'date'" class="inner-form-row">
              <v-col>
                <hb-date-picker
                  @click:clear="handleClear(section.key)"
                  :label="section.label"
                  :clearable="true"
                  :dense="true"
                  :data-vv-as="section.label"
                  v-model="form[section.key]"></hb-date-picker>
              </v-col>
            </v-row>



            <v-col class="hb-table-value" v-if="section.input === 'timeframe' && (!section.depends || form[section.depends.key] === section.depends.value)">
              <v-row class="inner-form-row" no-gutters>
                <v-col clas="pa-0 ma-0">

                  <v-select
                    :items="timeframe_options"
                    v-model="form[section.key].label"
                    hide-details
                    dense
                    :clearable="false"
                    @click:clear="handleClear(section.key, 'label')"
                    background-color="white"
                    placeholder="Select"
                  >
                  </v-select>
                </v-col>
              </v-row>

               <v-row v-if="form[section.key].label && form[section.key].label.toLowerCase() === 'custom range'" class="inner-form-row">
                 <v-col>
                   <hb-date-picker
                     @click:clear="handleClear(section.key, 'start_date')"
                     label="Start Date"
                     :clearable="false"
                     :dense="true"
                     data-vv-as="end date"
                     v-model="form[section.key].start_date"></hb-date-picker>
                 </v-col>
               </v-row>
              <v-row v-if="form[section.key].label && form[section.key].label.toLowerCase() === 'custom range'" class="inner-form-row">
                <v-col>
                  <hb-date-picker
                    @click:clear="handleClear(section.key, 'end_date')"
                    label="End Date"
                    :clearable="false"
                    :dense="true"
                    data-vv-as="end date"
                    v-model="form[section.key].end_date"></hb-date-picker>
                </v-col>
              </v-row>

            </v-col>


            <v-col class="hb-table-value" v-if="section.input === 'comparison' && (!section.depends || form[section.depends.key] === section.depends.value)">

              <v-row class="inner-form-row">
                <v-col >
                  <v-select
                    :items="comparison_options"
                    v-model="form[section.key].operator"
                    hide-details
                    dense
                    class="ma-0 pa-0"
                    @click:clear="handleClear(section.key, 'operator')"
                    clearable
                    background-color="white"
                    placeholder="Select"
                    :solo="!form[section.key].operator"
                    :flat="!form[section.key].operator"
                  >
                  </v-select>
                </v-col>
                <v-col v-if="form[section.key].operator && form[section.key].operator">
                  <v-text-field
                    v-model.lazy="form[section.key].value"
                    :prepend-inner-icon="section.column_type === 'money' ? 'mdi-currency-usd': ''"
                    dense

                    hide-details
                    :data-name="section.key"
                    :id="section.key"
                    background-color="white"
                    :aria-label="section.label"
                    singleLine
                    
                    :label="form[section.key].operator.toLowerCase() === 'range' ? 'From': 'Value'"
                  >
                  </v-text-field>
                </v-col>
                <v-col v-if="form[section.key].operator && form[section.key].operator.toLowerCase() === 'range'">
                  <v-text-field
                    v-model.lazy="form[section.key].max"
                    :prepend-inner-icon="section.column_type === 'money' ? 'mdi-currency-usd': ''"
                    dense

                    hide-details
                    :data-name="section.key"
                    :id="section.key"
                    background-color="white"
                    singleLine
                    :aria-label="section.label"
                    
                    label="To"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

            </v-col>

            <v-col class="hb-table-value" v-if="section.input === 'checkbox' && (!section.depends || form[section.depends.key] === section.depends.value)">
                 <div class="form-block w-form" v-if="section.input === 'checkbox'" >
                    <div class="checkbox">
                        <input
                          type="checkbox"
                          v-model="form[section.key]"
                          :id="'checkbox-' + section.key"
                          :name="'checkbox-' + section.key"
                          :data-name="'checkbox-' + section.key"
                          class="w-checkbox-input"
                        />

                        <label
                          :for="'checkbox-' + section.key"
                          class="w-form-label">{{ section.label }}</label>
                    </div>
                </div>
            </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-toolbar flat class="panel-footer">
      <hb-link class="mx-2" @click="$emit('close')">Reset</hb-link>
      <v-spacer></v-spacer>
      <hb-link class="mr-2" @click="$emit('close')">Cancel</hb-link>
      <hb-btn color="primary" class="mr-1" @click="search">Filter Rows</hb-btn>
    </v-toolbar>

    </div>

</template>

<script type="text/babel">

    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import moment from 'moment';
    import { mapGetters } from "vuex";
    export default {
        name: "Filters",
        data() {
            return {
                active_group: '',
                opened_sections: [],
                form :{},
                form_loaded: false,
                source_data:{},
                slider_configs:{},
                boolean_options: [
                  {
                      id: 1,
                      name: 'Yes'
                  },
                  {
                    id: -1,
                    name: 'No'
                }
              ],
                timeframe_options: ['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'This Month', 'Last Month', 'Year To Date', 'Custom Range'],
                comparison_options: ['Greater Than', 'Less Than', 'Equals','Not Equals', 'Greater Than or Equals', 'Less Than or Equals', 'Between']
            }
        },
        components:{
            HbDatePicker
        },
        mounted(){},

        async created(){
            this.source_data = this.filter_data;
            // Populate the form with defaults from the server
            let f = JSON.parse(JSON.stringify(this.filters));
            let reportPeriod = this.filters?.search?.report_period;
            let reportPeriodFromStore = this.geDynamicRunParam?.timePeriodConfig?.report_period;
            if (reportPeriod && reportPeriodFromStore != undefined) {
              f.search.report_period = reportPeriodFromStore;
            }
            this.form = JSON.parse(JSON.stringify(f.search));

            // Fetch data for the form and put in the store
            await this.loadFormData(this.filter_structure);
            this.$nextTick(() => {
                this.form_loaded = true;
            });
        },

        destroyed(){},
        computed:{
            filter_structure_groups(){
                return [...new Set(this.filter_structure.map(g => g.group))].filter(g => g !== 'facility')
            },
            filtered_groups(){
                return this.filter_structure.filter(fs => fs.group === this.active_group);
            },
            ...mapGetters({
                geDynamicRunParam: 'reportStore/geDynamicRunParam',
                lead_source: 'filterStore/lead_source',
                geDynamicRunParam: 'reportStore/geDynamicRunParam',
            })

        },
        filters:{
          removeUnderscore(name){
            return name.replace('_', ' ');
          }
        },

        props: ['filter_structure', 'filters'],

        methods:{
            handleClear(field, input){
               //this.form[field][input] = '';
            },
            search(){
                if(!this.form_loaded) return;
                this.validate(this).then(status => {
                  if(!status) return;
                    console.log("this.form", this.form);
                  this.$emit('search', JSON.parse(JSON.stringify(this.form)))
                })

            },
            isEmpty(field){
                if(Array.isArray(field) && field.length){
                    return false;
                } else if (_.isObject(field) && field.value) {
                    return false;
                } else if (_.isObject(field) && field.label) {
                    return false;
                }
                return true;
            },
            openSetFilters(data){
                for(let d in data){
                    if(!this.isEmpty(data[d])){
                        this.opened_sections.push(d)
                    }
                }
            },
            toggle_filter_section(section){
                let index = this.opened_sections.findIndex(os => os === section.key);
                if(index >= 0) {
                    this.opened_sections.splice(index, 1)
                } else {
                    this.opened_sections.push(section.key)
                }
            },
            setActiveGroup(group){
                this.active_group = group;
            },

            async loadFormData(structure){

                if( structure.find(s => s.key === 'property_id')) await this.$store.dispatch('filterStore/getProperties');
                if( structure.find(s => s.key === 'unit_floor')) await this.$store.dispatch('filterStore/getFloors');
                if( structure.find(s => s.key === 'unit_category_id')) await this.$store.dispatch('filterStore/getCategories');
                if( structure.find(s => s.key === 'product_id')) await this.$store.dispatch('filterStore/getProducts');
                if( structure.find(s => s.key === 'lead_source')) await this.$store.dispatch('filterStore/getLeadSources');
                if( structure.find(s => s.key === 'tenant_last_contacted_by')) await this.$store.dispatch('filterStore/getAdmins');
                if( structure.find(s => s.key === 'lease_sign_up_promo_id')) await this.$store.dispatch('filterStore/getPromotions');
                if( structure.find(s => s.key === 'lease_last_payment_source')) await this.$store.dispatch('filterStore/getPaymentSources');
                if( structure.find(s => s.key === 'trigger_event_type')) await this.$store.dispatch('filterStore/getEventTypes');
                if( structure.find(s => s.key === 'lease_standing_id'))  await this.$store.dispatch('filterStore/getLeaseStandings');


                // Should be in settings
                if( structure.find(s => s.key === 'tenant_last_contacted_method')) await this.$store.dispatch('filterStore/getContactMethods');
                if( structure.find(s => s.key === 'maintenance_type')) await this.$store.dispatch('filterStore/getMaintenanceTypes');
                if( structure.find(s => s.key === 'unit_type')) await this.$store.dispatch('filterStore/getUnitTypes');

                // if( structure.find(s => s.key === 'method_type')) await this.$store.dispatch('filterStore/getPaymentMethodTypes');
                // if( structure.find(s => s.key === 'product_type')) await this.$store.dispatch('filterStore/getProductTypes');

                if( structure.find(s => s.key === 'payment_status')) await this.$store.dispatch('filterStore/getPaymentStatuses');
                if( structure.find(s => s.key === 'reservation_unit_id')) await this.$store.dispatch('filterStore/getUnits');
                // if( structure.find(s => s.key === 'lease_status')) await this.$store.dispatch('filterStore/getLeaseStatuses');



                if( structure.find(s => s.key === 'application_status')) await this.$store.dispatch('filterStore/getApplicationStatuses');
                if( structure.find(s => s.key === 'lead_last_contacted_by')) await this.$store.dispatch('filterStore/getAdmins');
                if( structure.find(s => s.key === 'lead_last_contacted_method')) await this.$store.dispatch('filterStore/getContactMethods');
                if( structure.find(s => s.key === 'payment_source')) await this.$store.dispatch('filterStore/getPaymentSources');
                if( structure.find(s => s.key === 'reservation_property_id')) await this.$store.dispatch('filterStore/getProperties');
                if( structure.find(s => s.key === 'reservation_category_id')) await this.$store.dispatch('filterStore/getCategories');
                if( structure.find(s => s.key === 'lead_property_id')) await this.$store.dispatch('filterStore/getProperties');
                if( structure.find(s => s.key === 'lead_category')) await this.$store.dispatch('filterStore/getCategories');
                if( structure.find(s => s.key === 'lead_unit_id')) await this.$store.dispatch('filterStore/getUnits');
                if( structure.find(s => s.key === 'task_created_by')) await this.$store.dispatch('filterStore/getAdmins');
                if( structure.find(s => s.key === 'task_assigned_to')) await this.$store.dispatch('filterStore/getAdmins');
                if( structure.find(s => s.key === 'trigger_fee_product')) await this.$store.dispatch('filterStore/getProducts');
                if( structure.find(s => s.key === 'lead_converted_to_reservation_by')) await this.$store.dispatch('filterStore/getLeadConvertedToReservedBy');
                if( structure.find(s => s.key === 'lead_converted_to_rental_by')) await this.$store.dispatch('filterStore/getLeadConvertedToRentalBy');

                //if( structure.find(s => s.key === 'trigger_fee_type')) await this.$store.dispatch('filterStore/getProductTypes');
                //if( structure.find(s => s.key === 'trigger_lease_status_update')) await this.$store.dispatch('filterStore/getLeaseStatuses');





                  // if(typeof structure.unit_type !== 'undefined'){
                  // await this.$store.dispatch('getUnitTypes');
                  // if(this.$store.getters.unit_type){
                  //     this.$set(this.form, 'unit_type', this.$store.getters.unit_type[0] )
                  // }
                  // this.filters.search.unit_type = unit_types[0];
                // }

            },
        },
        watch:{
            form: {
                handler: function (newParams) {
                    if(this.form_loaded){
	                    this.validate(this).then(status => {
	                    	if(status) {
                         // this.$emit('search', this.form)
                        }
	                    })
                    }
                },
                deep: true
            },
	          // filters: {
            //   handler: function(newParams) {
            //       this.form = newParams.search;
            //       // this.filters = newParams;
            //   },
            //   deep: true
            // },
            filter_data: {
                handler: function () {
                    this.source_data = this.filter_data;
                },
                deep: true
            }
        }




    }

</script>

<style scoped>
    .slider-inputs {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;
        align-items: baseline;
    }

    .slider-inputs .lider-input-field{
        flex-basis: 25%;

    }
    .slider-inputs .slider-spacer{

        flex-basis: 50%;
        text-align: center;
    }

  .dependent{
    background-color: #dee5e7;
  }
    .filter-label{
        font-size: 14px;
        padding: 15px;
        display: block;
    }

    .filter-label.active{
        color: #00848E;
        font-weight: 500;
    }
    .filter-form-row{
        border-bottom: 1px solid #E0E3E8;

    }
    .filter_section {
        padding: 0px 15px 10px;
        margin-top: 0;
    }
    .filter-group_picker{
        padding: 5px 0;
        border-bottom: 1px solid #E0E3E8;
        background-color: #F4F6F8;
    }
    .filter-group-btn{
        text-transform: none;
        font-size: 14px;
    }

    .v-expansion-panels .v-expansion-panel.v-expansion-panel--active > .v-expansion-panel-header {
      min-height: auto;
    }
    .options-row{
      padding: 5px 0;
    }

</style>
